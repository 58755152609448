
						
.tBRM,.tSNC,.tData {
	width: 100%;
	padding: 10px;
	border-collapse: collapse;
  /*border-color: gray;
  border-style: solid;*/
}

table.tBRM td, table.tBRM th,table.tSNC td, table.tSNC th,table.tData td {
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  padding: 5px;
}
.tBRMHeadT,.tSNCHeadT {
	width:30%;
}
.tBRMHeadB,.tBRMHeadR,.tBRMHeadM,.tBRMBodyB,.tBRMBodyR,.tBRMBodyM,
.tSNCHeadS,.tSNCHeadN,.tSNCBodyS,.tSNCBodyN  {
	width:5%;
	text-align: center;
}
.tIMGc {
	text-align: center;
}
.tSNCHeadCant,.tSNCBodyCant {
	width:10%;
	text-align: center;
}

.tDataD1,.tDataD3 {
	width:20%;
	font-weight:bold;
}

.tDataD2,.tDataD4 {
	width:30%;
}

.tIMGInCL {
	max-width:60%;
	max-height:300px;
}